import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//import withRouter
import withRouter from "../../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";

// users
import userIcon from "../../../assets/images/users/user-icon.png";

//redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import { User } from "src/util/types";

const ProfileMenu = (props: any) => {

  const selectLeadData = createSelector(
    (state: any) => state.login.user,
    (leadsChart) => leadsChart
  );
  // Inside your component
  const success = useSelector(selectLeadData);

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");
  const [email, setemail] = useState<string>("admin@themesbrand.com");
  const [profile, setProfile] = useState<string>("Administrador");

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      const obj: User = JSON.parse(getAuthUser);
      
      if (obj.name) {
        setemail(obj.email);
        setusername(obj.name);
        setProfile(obj.profile)
      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userIcon}
            alt="Header Avatar"
          />
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title">{profile}</span> 
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{username}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">
              {email}
            </p>
          </div>
          <DropdownItem>
            <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/profile"> {props.t("Perfil")}</Link>
            </span>
          </DropdownItem>
          <DropdownItem>
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/logout">{props.t("Cerrar sesión")}</Link>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
