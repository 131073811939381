import { ContentState, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';


export const loadDraft = (htmlContent: string) => {
  const blocksFromHTML = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'img') {
        const src = node.getAttribute('src');
        return createEntity('IMAGE', 'IMMUTABLE', { src });
      }
      return '';
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === "img") {
        return {
          type: "atomic",
          data: {
            src: node.getAttribute("src"),
          },
        };
      }
    }
  })(htmlContent);

  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.getBlocksAsArray(),
    blocksFromHTML.getEntityMap()
  );

  return EditorState.createWithContent(contentState);
};