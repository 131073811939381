import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addNewComm, addNewCommResponse, fetchCommunicationById, fetchCommunications, validatePassForSignComm } from './thunks';
import { CommActionState, Communication, CommunicationState, OpenComm } from '../../util/types';

const initialState: CommunicationState = {
  communications: [],
  comms_status: CommActionState.NOT_STARTED,
  comms_msg: '',
  selected_comm: null,
  comm_responses: [],
  selected_comm_status: CommActionState.NOT_STARTED,
  selected_comm_msg: '',
  new_comm_status: CommActionState.NOT_STARTED,
  new_comm_msg: '', 
  validating_status: CommActionState.NOT_STARTED,
  validating_msg: '', 
  savedCommId: ''
};

export const communicationsSlice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    resetCommsState: () => initialState,
    onChangePassword: (state) => {
      state.validating_status = CommActionState.NOT_STARTED;
      state.validating_msg = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunications.pending, (state) => {
        state.comms_status = CommActionState.LOADING;
      })
      .addCase(fetchCommunications.fulfilled, (state, action: PayloadAction<Communication[]>) => {
        state.comms_status = CommActionState.SUCCESS;
        state.communications = action.payload;
      })
      .addCase(fetchCommunications.rejected, (state, action) => {
        state.comms_status = CommActionState.ERROR;
        state.comms_msg = (action.error.message) ?? 'Error al cargar las comunicaciones.';
      })
      .addCase(fetchCommunicationById.pending, (state) => {
        state.selected_comm_status = CommActionState.LOADING;
      })
      .addCase(fetchCommunicationById.fulfilled, (state, action: PayloadAction<OpenComm | undefined>) => {
        state.selected_comm_status = CommActionState.SUCCESS;
        if(action.payload) {
          state.selected_comm = action.payload.open_comm;
          state.comm_responses = action.payload.comm_responses;
        }
      })
      .addCase(fetchCommunicationById.rejected, (state, action) => {
        state.selected_comm_status = CommActionState.ERROR;
        state.selected_comm_msg = (action.error.message) ?? 'Error al cargar la comunicación.';
      })
      .addCase(addNewCommResponse.pending, (state) => {
        state.comms_status = CommActionState.LOADING;
      })
      .addCase(addNewCommResponse.fulfilled, (state) => {
        state.comms_status = CommActionState.SUCCESS;
      })
      .addCase(addNewCommResponse.rejected, (state, action) => {
        state.comms_status = CommActionState.ERROR;
        state.comms_msg = (action.error.message) ?? 'Error al agregar la comunicación.';
      })
      .addCase(addNewComm.pending, (state) => {
        state.new_comm_status = CommActionState.LOADING;
      })
      .addCase(addNewComm.fulfilled, (state, action: PayloadAction<string>) => {
        state.new_comm_status = CommActionState.SUCCESS;
        state.new_comm_msg = 'Comunicación agregada correctamente.';
        state.savedCommId = action.payload;
      })
      .addCase(addNewComm.rejected, (state, action) => {
        state.new_comm_status = CommActionState.ERROR;
        state.new_comm_msg = (action.error.message) ? `Error al agregar la comunicación. Codigo: ${action.error.code}` : '';
      })
      .addCase(validatePassForSignComm.rejected, (state) => {
        state.validating_status = CommActionState.ERROR;
        state.validating_msg = 'Contraseña incorrecta';
      })  
      .addCase(validatePassForSignComm.pending, (state) => {
        state.validating_status = CommActionState.LOADING;
      })  
      .addCase(validatePassForSignComm.fulfilled, (state) => {
        state.validating_status = CommActionState.SUCCESS
        state.validating_msg = 'Comunicación firmada correctamente.';
      })  

  },
});

export const { resetCommsState, onChangePassword } = communicationsSlice.actions;
export default communicationsSlice.reducer;
