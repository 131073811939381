import React from "react";

import { Route, Routes } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";


// Import scss
import "./assets/scss/theme.scss";

import { createSelector } from "reselect";


function getLayout(layoutType: string) {
  let layoutCls: Object = VerticalLayout;
  switch (layoutType) {
    case "horizontal":
      layoutCls = HorizontalLayout;
      break;
    default:
      layoutCls = VerticalLayout;
      break;
  }
  return layoutCls;
}

const App = () => {

  const selectLayoutProperties = createSelector(
    (state:any) => state.Layout,
    (layout:any) => ({
      layoutType: layout.layoutType,
    })
  );

  // Inside your component
  const {
    layoutType
  } = useSelector(selectLayoutProperties);

  const Layout: any = getLayout(layoutType);
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;