import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Container,
  CardHeader,
  Button,
  Input,
  Label,
  FormFeedback,
  Alert,
  Spinner
} from "reactstrap";
import Dropzone from "react-dropzone";

//Date Picker
import "react-datepicker/dist/react-datepicker.css";

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { getFileIcon } from "src/util/files";
import { FileActionState, SubirArchivo } from "src/util/types";
import { RootState } from "src";
import { doneUploadingFile } from "src/slices/FileManager/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { addFile } from "src/slices/FileManager/thunks";


const NewFile = () => {
  document.title = "Nuevo documento";
  const { uploadStatus, uploadMsg} = useSelector((state: RootState) => state.fileManager);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();

  const { contractSelected } = useSelector( (state: RootState) => state.login );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nombre: "",
      nombre_documento: "",
      descripcion: "",
      date: "",
      tipo: "",
      mime_type: ""
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Por favor ingresa el nombre del documento."),
      descripcion: Yup.string().required("Por favor ingresa una descripción."),
      date: Yup.string(),
      tipo: Yup.string().required("Por favor ingresa el tipo de documento.."),
    }),
    onSubmit: async values => {
      const fecha_emision = (values.date) ? new Date(values.date).toISOString() : null;
      const data : SubirArchivo = {
        descripcion: values.descripcion,
        nombre: values.nombre,
        nombre_documento: values.nombre_documento,
        fecha_emision,
        mime_type: values.mime_type,
        tipo: values.tipo,
        contractid: contractSelected.id
      };
      dispatch(addFile(data, selectedFiles[0]));
      validation.resetForm();
      setSelectedFiles([]);
    },
  });

  function handleAcceptedFiles(files: File[]) {
    files.map((file) => {
      Object.assign(file, {
        formattedSize: formatBytes(file.size),
        iconClass: getFileIcon(file.type)
      })
      validation.setFieldValue("nombre", file.name);
      validation.setFieldValue("nombre_documento", file.name);
      validation.setFieldValue("mime_type", file.type)
    });
    dispatch(doneUploadingFile())
    setSelectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Documentos" breadcrumbItem="Subir nuevo documento" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="card-header justify-content-between d-flex align-items-center">
                  <h4 className="card-title">Zona de carga</h4>
                </CardHeader>
                <CardBody>

                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                      maxFiles={1}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted uil uil-cloud-upload"></i>
                            </div>
                            <h4>Arrastra los documentos aquí o haz click para buscar en tu equipo.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f: any, i: number) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <i className={f.iconClass} />
                                  {/* <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  /> */}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <Row className="my-4"> {/* Nombre */}
                      <Label htmlFor="file-name" className="col-sm-3 col-form-label">Nombre</Label>
                      <Col sm={6}>
                        <Input
                          name="nombre"
                          placeholder="Nombre del documento"
                          type="text"
                          className="form-control"
                          id="file-name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.nombre || ""}
                          invalid={
                            validation.touched.nombre &&
                              validation.errors.nombre
                              ? true
                              : false
                          }
                        />
                        {validation.touched.nombre &&
                          validation.errors.nombre ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nombre}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-4"> {/* Descripción */}
                      <Label htmlFor="file-description" className="col-sm-3 col-form-label">Descripción</Label>
                      <Col sm={6}>
                        <Input
                          name="descripcion"
                          placeholder="Descripción del documento"
                          type="text"
                          className="form-control"
                          id="file-description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.descripcion || ""}
                          invalid={
                            validation.touched.descripcion &&
                              validation.errors.descripcion
                              ? true
                              : false
                          }
                        />
                        {validation.touched.descripcion &&
                          validation.errors.descripcion ? (
                          <FormFeedback type="invalid">
                            {validation.errors.descripcion}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="my-4"> {/* Tipo */}
                      <Label htmlFor="file-type" className="col-sm-3 col-form-label">Tipo</Label>
                      <Col sm={6}>
                        <Input
                          name="tipo"
                          placeholder="Tipo de documento"
                          type="text"
                          className="form-control"
                          id="file-type"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tipo || ""}
                          invalid={
                            validation.touched.tipo &&
                              validation.errors.tipo
                              ? true
                              : false
                          }
                        />
                        {validation.touched.tipo &&
                          validation.errors.tipo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tipo}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="my-4"> {/* Fecha de emisión */}
                      <Label htmlFor="file-date" className="col-sm-3 col-form-label">Fecha de emisión</Label>
                      <Col sm={6}>
                        <Input
                          name="date"
                          placeholder="Tipo de documento"
                          type="date"
                          className="form-control"
                          id="file-date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.date || ""}
                          invalid={
                            validation.touched.date &&
                              validation.errors.date
                              ? true
                              : false
                          }
                        />

                      </Col>
                    </Row>

                    <Row>
                    </Row>
                    <Button color="primary" type="submit" disabled={selectedFiles.length === 0}>
                      Subir documento
                    </Button>
                  </Form>
                  {
                    (uploadStatus === FileActionState.LOADING) &&
                      <Spinner
                        className="spinner-border text-primary mt-4"
                        role="status"
                      ></Spinner>
                  }
                  {
                    (uploadStatus === FileActionState.SUCCESS || uploadStatus === FileActionState.ERROR) &&
                      <Alert color={ uploadStatus === FileActionState.SUCCESS ? 'success' : 'danger'} className="mt-4">
                        { uploadMsg }
                      </Alert>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewFile;
