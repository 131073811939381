import { Card, CardBody, Container } from "reactstrap"
import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CommsAdmin } from "./CommsAdmin";


const Comunications = () => {

  document.title = "Administrador de comunicaciones";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Apps" breadcrumbItem="Administrador de comunicaciones" />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <CommsAdmin />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Comunications