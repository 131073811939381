import { Collapse } from 'reactstrap';
import classnames from "classnames";
import DOMPurify from 'dompurify';
import React, { useState } from 'react'

import { Communication } from 'src/util/types';
import { isoToShortDate } from 'src/util/date';


export const CommDetail: React.FC<Communication> = (selectedComm) => {
  const [col1, setcol1] = useState(true);

  const t_col1 = () => {
    setcol1(!col1);
  }

  const sanitizedHTML = (html: string) => {
    return DOMPurify.sanitize(html);
  }

  return (
    <div className="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col1 }
            )}
            type="button"
            onClick={t_col1}
            style={{ cursor: "pointer" }}
          >
            <div className="flex-1">
              <h5 className="font-size-14 my-1">
                {selectedComm.comm_type} ({(selectedComm.creation_date) ? isoToShortDate(selectedComm.creation_date) : ""})
              </h5>
            </div>
          </button>
        </h2>
        <Collapse isOpen={col1}>
          <div className="p-3">
            <div className="flex-1">
              <h5 className="font-size-14 my-1">
                {selectedComm.author.name}
              </h5>
              <small className="text-muted">{selectedComm.author.email}</small>
            </div>
            <div>
              <h4 className="mt-4 mb-4 font-size-16">
                {selectedComm.comm_type}
              </h4>

              <p className="text-muted" dangerouslySetInnerHTML={{ __html: sanitizedHTML(selectedComm.body) }}></p>

            </div>
          </div>

        </Collapse>
      </div>
    </div>
  )
}
