import { User } from "src/util/types";

export function getUser(): User | null {
	const userLocalStorage = localStorage.getItem("authUser");
	if( userLocalStorage ){
		return JSON.parse(userLocalStorage);
	}
  return null;
}

export function setUser(user: User): void {
  localStorage.setItem("authUser", JSON.stringify(user));
}

export function clearUser(): void {
  localStorage.removeItem("authUser");
}
