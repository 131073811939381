import { EditorState } from 'draft-js';
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { FormikProps } from "formik";

import { COMMUNICATION_TYPE, FormValues } from "src/util/types";

interface CommunicationFormProps {
  formik: FormikProps<FormValues>; 
  editorState: EditorState; 
  handleEditorChange: (state: EditorState) => void;
  uploadImageCallback: (file: File) => Promise<any>;
  onSubmit: () => void;
}

const CommunicationForm: React.FC<CommunicationFormProps> = ({
  editorState,
  handleEditorChange,
  uploadImageCallback,
  onSubmit,
  formik
}) => {

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
      <Row className="my-4">
        <Label htmlFor="file-name" className="col-sm-3 col-form-label">Tipo de comunicación</Label>
        <Col sm={6}>
          <Input
            name="commType"
            type="select"
            className="form-select"
            value={formik.values.commType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="" label="Selecciona una opción" />
            <option value={COMMUNICATION_TYPE.SOLICITUD}>Solicitud</option>
            <option value={COMMUNICATION_TYPE.CONSULTA}>Consulta</option>
            <option value={COMMUNICATION_TYPE.ENTREGA_INFO}>Entrega de información</option>
            <option value={COMMUNICATION_TYPE.INSTRUCCION}>Instrucción</option>
            <option value={COMMUNICATION_TYPE.NOTIF_INCUMPLIMIENTO}>Notificación de incumplimiento</option>
            <option value={COMMUNICATION_TYPE.CONSTANCIA}>Constancia</option>
            <option value={COMMUNICATION_TYPE.ORDEN_CAMBIO}>Orden de cambio</option>
            <option value={COMMUNICATION_TYPE.RECLAMO}>Reclamo</option>

          </Input>
        </Col>
      </Row>

      <Row className="my-4"> {/* Cuerpo */}
        <Label htmlFor="file-name" className="col-sm-3 col-form-label">Cuerpo</Label>
        <Col sm={6}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "image", "history"],
              image: {
                uploadEnabled: true,
                uploadCallback: uploadImageCallback,
                previewImage: true,
                alt: { present: true, mandatory: false },
              },
            }}
          />
          {formik.errors.editorContent && (
            <div className="text-danger">{formik.errors.editorContent}</div>
          )}
        </Col>
      </Row>

      <Row className="my-4"> {/* Fecha límite */}
        <Label htmlFor="deadline" className="col-sm-3 col-form-label">Fecha límite</Label>
        <Col sm={6}>
          <Input
            name="deadline"
            placeholder="Fecha límite"
            type="date"
            className="form-control"
            id="deadline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.deadline || ""}
            invalid={
              formik.touched.deadline &&
                formik.errors.deadline
                ? true
                : false
            }
          />

        </Col>
      </Row>
      <Row className="mb-4"> {/* requiere fecha límite? */}
        <Col sm={3}></Col>
        <Col sm={6}>
          <div className="form-check">
            <Input
              name="noDeadline"
              type="checkbox"
              className="form-check-input"
              id="noDeadline"
              onChange={formik.handleChange}
              checked={formik.values.noDeadline}
              disabled={formik.values.commType === "instruccion"} />
            <Label className="form-check-label" htmlFor="noDeadline">No tiene fecha límite</Label>
          </div>
        </Col>
      </Row>
      <Row className="mb-4"> {/* Archivo adjunto */}
        <Label htmlFor="formFile" className="col-sm-3 col-form-label">
          Archivo adjunto
        </Label>
        <Col sm={6}>
          <Input className="form-control" type="file" id="formFile" />
        </Col>
      </Row>
      <Row className="mb-4"> {/* Requiere respuesta */}
        <Col sm={3}></Col>
        <Col sm={6}>
          <div className="form-check">
            <Input
              name="mustRespond"
              type="checkbox"
              className="form-check-input"
              id="mustRespond"
              onChange={formik.handleChange}
              checked={formik.values.mustRespond}
            />
            <Label className="form-check-label" htmlFor="mustRespond">Requiere respuesta</Label>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CommunicationForm;
