import { Alert, Input, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import DOMPurify from 'dompurify';
import React, { useEffect } from "react";

import { CommActionState, COMMUNICATION_TYPE } from "src/util/types";
import { fetchCommunications } from "src/slices/Communications/thunks";
import { RootState } from "src";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import EmailToolbar from "./email-toolbar";



export const CommsAdmin = () => {

  const dispatch = useAppDispatch();

  const { comms_msg, comms_status, communications } = useSelector(
    (state: RootState) => state.communications
  );

  const badgeColorForCommType = (commType: string) => {
    switch (commType) {
      case COMMUNICATION_TYPE.INSTRUCCION:
        return "badge-warning-subtle";
      case COMMUNICATION_TYPE.SOLICITUD:
        return "badge-primary-subtle";
      case COMMUNICATION_TYPE.CONSULTA:
        return "badge-success-subtle";
      case COMMUNICATION_TYPE.ENTREGA_INFO:
        return "badge-info-subtle";
      case COMMUNICATION_TYPE.NOTIF_INCUMPLIMIENTO:
        return "badge-danger-subtle";
      default:
        return "badge-secondary-subtle";
    }
  }

  function stripHtmlTags(html: string): string {
    const sanitizedHTML = DOMPurify.sanitize(html);
    const div = document.createElement('div');
    div.innerHTML = sanitizedHTML;
    return div.innerText;
  }

  useEffect(() => {
    dispatch(fetchCommunications());
  }, [dispatch]);

  return (

    <React.Fragment>
      {
        (comms_status === CommActionState.ERROR) &&
        <Alert color='danger' className="mt-4">
          {comms_msg}
        </Alert>
      }
      {
        (comms_status === CommActionState.LOADING) &&
        <Spinner
          className="spinner-border text-primary mt-4"
          role="status"
        ></Spinner>
      }
      {
        (comms_status === CommActionState.SUCCESS) &&
        <>
          <EmailToolbar />

          <ul className="message-list">
            {
              communications.map((comm) => (
                <li key={comm.id} className={comm.read ? "" : "unread"}>
                  <div className="col-mail col-mail-1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id={`chk${comm.id}`} />
                      <Label htmlFor={`chk${comm.id}`} className="toggle" />
                    </div>
                    <Link to={`/comunicaciones/abrir/${comm.id}`} className="title">
                      {comm.author.name} {comm.totalReplies > 0 ? `(${comm.totalReplies})` : ""}
                    </Link>
                  </div>
                  <div className="col-mail col-mail-2">
                    <Link to="#" className="subject">
                      <span className={classNames("badge", badgeColorForCommType(comm.comm_type), "me-2")}>
                        {comm.comm_type}
                      </span>
                      <span className="teaser" dangerouslySetInnerHTML={ { __html: stripHtmlTags(comm.body).substring(0, 100) + '...' } }>
                      </span>
                    </Link>
                    <div className="date">
                      {new Date(comm.creation_date).toLocaleDateString()}
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </>
      }

    </React.Fragment>
  );
}
