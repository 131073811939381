import { Alert, Card, CardBody, Container, Input, Label, Spinner } from "reactstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import classNames from "classnames";
import DOMPurify from 'dompurify';
import React, { useEffect } from "react";

import { CommActionState } from "src/util/types";
import { fetchDraftComms, getDraftById } from "src/slices/Draft Communications/thunks";
import { RootState } from "src";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import Breadcrumbs from "../../components/Common/Breadcrumb"




export const CommsDraft = () => {

  const dispatch = useAppDispatch();

  const { draftComms, draftStatus, draftMsg } = useSelector(
    (state: RootState) => state.draftComms
  );

  const navigate = useNavigate();

  const openDraft = (id: string | undefined) => {
    if(!id) return
    dispatch(getDraftById(id))
    navigate("/comunicaciones/nuevo")
  }

  function stripHtmlTags(html: string): string {
    const sanitizedHTML = DOMPurify.sanitize(html);
    const div = document.createElement('div');
    div.innerHTML = sanitizedHTML;
    return div.innerText;
  }

  useEffect(() => {
    dispatch(fetchDraftComms())
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administrador de comunicaciones" breadcrumbItem="Borradores" />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      {
                        (draftStatus === CommActionState.ERROR) &&
                        <Alert color='danger' className="mt-4">
                          {draftMsg}
                        </Alert>
                      }
                      {
                        (draftStatus === CommActionState.LOADING) &&
                        <Spinner
                          className="spinner-border text-primary mt-4"
                          role="status"
                        ></Spinner>
                      }
                      {
                        (draftStatus === CommActionState.SUCCESS) && draftComms.length === 0 &&
                        <Alert color='warning' className="mt-4">
                          No hay borradores para mostrar.
                        </Alert>
                      }
                      {
                        (draftStatus === CommActionState.SUCCESS) &&
                        <ul className="message-list">
                          {
                            draftComms.map((draft) => (
                              <li key={draft.id}>
                                <div className="col-mail col-mail-1">
                                  <div className="checkbox-wrapper-mail">
                                    <Input type="checkbox" id={`chk${draft.id}`} />
                                    <Label htmlFor={`chk${draft.id}`} className="toggle" />
                                  </div>
                                  <div onClick={ () =>openDraft(draft.id) }className="title">
                                    Borrador
                                  </div>
                                </div>
                                <div className="col-mail col-mail-2">
                                  <div className="subject" onClick={ () =>openDraft(draft.id) }>
                                    <span className={classNames("badge", "badge-secondary-subtle", "me-2")}>
                                      {(draft.comm_type) ? draft.comm_type : "Borrador"}
                                    </span>
                                    <span className="teaser" dangerouslySetInnerHTML={{ __html: stripHtmlTags((draft.body) ?? "").substring(0, 100) + '...' }}>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      }
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
