import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Alert
} from "reactstrap";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { getFiles } from "src/slices/FileManager/thunks";
import { RootState } from '../../index';
import { isLoadingFilesSelector, setFilterFiles, setOriginalFiles } from "src/slices/FileManager/reducer";
import { FilesTable } from "./FilesTable";
import { FileActionState } from "src/util/types";

const Archivos = (prop: any) => {

  const dispatch = useAppDispatch()
  const { files, filesMsg, filesStatus } = useSelector((state: RootState) => state.fileManager);
  const { contractSelected } = useSelector((state: RootState) => state.login);
  const [filtro, setFiltro] = useState("")
  const isLoadingFiles = useSelector(isLoadingFilesSelector);
  
  useEffect(() => {
    dispatch( getFiles(contractSelected.id) );
  }, [contractSelected])

  const buscar = (event: any) => {
    setFiltro(event.target.value)
    filtrarDocs(event.target.value)
  }

  const filtrarDocs = (filtro: string) => {
    if(filtro !== '') {
      dispatch(setFilterFiles({filtro}))
    } else {
      dispatch(setOriginalFiles())
    }
  }


  return (
    <React.Fragment>

      {
        (isLoadingFiles) &&
        <>
        <Spinner
          className="spinner-border text-primary m-1 m-auto"
          role="status"
        ></Spinner>
        </>
      }
      {
        (filesStatus === FileActionState.ERROR) &&
        <Alert color='danger' className="mt-4">
          { filesMsg }
        </Alert>
      }
      {
        (filesStatus === FileActionState.SUCCESS) &&
        <>
          <div>
            <Row className="mb-3">
              <Col lg={4} sm={6}>
                <div className="search-box mb-2">
                  <div className="position-relative">
                    <input 
                      type="text" 
                      className="form-control bg-light border-light rounded" 
                      placeholder="Buscar..." 
                      value = { filtro }
                      onChange = { buscar }
                      />
                    <i className="uil uil-search search-icon"></i>
                  </div>
                </div>
              </Col>
              <Col lg={8} sm={6}>
                <div className="mt-4 mt-sm-0 d-flex align-items-center justify-content-sm-end">
                  <div className="mb-2 me-2">
                    <Link to="/repositorio/subir-documento" className="btn btn-primary">
                      Subir documento
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <hr className="mt-2" />
            {
              (files.length > 0) 
              ?
              <FilesTable files={files}></FilesTable>
              :
              <Alert color='warning' className="mt-4">
                No hay documentos para mostrar.
              </Alert>
            }
          </div>
        </>
      }
    </React.Fragment>
  );
};

export default Archivos;
