import { createAsyncThunk } from "@reduxjs/toolkit";

import { DraftComm, SaveDraft } from "../../util/types";
import { getDraft, getDrafts, saveDraft, updateDraft } from "../../api/api";

export const fetchDraftComms = createAsyncThunk(
  "draftComms/fetchDraftComms",
  async (_, { rejectWithValue }) => {
    try {
      return await getDrafts();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addDraftComm = createAsyncThunk(
  "draftComms/addDraftComm",
  async (draftComm: SaveDraft, { rejectWithValue }) => {
    try {
      await saveDraft(draftComm);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDraftById = createAsyncThunk(
  "draftComms/getDraftById",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getDraft(id)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)


export const updateDraftComm = createAsyncThunk(
  "draftComms/updateDraftComm",
  async (draftComm: DraftComm, { rejectWithValue }) => {
    try {
      return await updateDraft(draftComm);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDraftComm = createAsyncThunk(
  "draftComms/deleteDraftComm",
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteDraftComm(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
