import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addDraftComm, fetchDraftComms, getDraftById, updateDraftComm } from './thunks';
import { CommActionState, Communication } from '../../util/types';
import { DraftComm } from '../../util/types';

interface DraftCommState {
  draftComms: DraftComm[];
  draftStatus: CommActionState;
  draftUpdateStatus: CommActionState;
  draftMsg: string;
  draftSelected: DraftComm | null;
}

const initialState: DraftCommState = {
  draftComms: [],
  draftStatus: CommActionState.NOT_STARTED,
  draftUpdateStatus: CommActionState.NOT_STARTED,
  draftMsg: '',
  draftSelected: null
};

export const draftCommsSlice = createSlice({
  name: 'draftComms',
  initialState,
  reducers: {
    resetDraftState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDraftComms.pending, (state) => {
        state.draftStatus = CommActionState.LOADING;
      })
      .addCase(fetchDraftComms.fulfilled, (state, action: PayloadAction<DraftComm[]>) => {
        state.draftStatus = CommActionState.SUCCESS;
        state.draftComms = action.payload;
      })
      .addCase(fetchDraftComms.rejected, (state, action) => {
        state.draftStatus = CommActionState.ERROR;
        state.draftMsg = action.error.message ?? 'Error al cargar los borradores.';
      })
      .addCase(addDraftComm.pending, (state) => {
        state.draftStatus = CommActionState.LOADING;
      })
      .addCase(addDraftComm.fulfilled, (state) => {
        state.draftStatus = CommActionState.SUCCESS;
        state.draftMsg = 'Borrador guardado exitosamente.'
      })
      .addCase(addDraftComm.rejected, (state, action) => {
        state.draftStatus = CommActionState.ERROR;
        state.draftMsg = action.error.message ?? 'Error al agregar el borrador.';
      })
      .addCase(updateDraftComm.pending, (state) => {
        state.draftUpdateStatus = CommActionState.LOADING;
      })
      .addCase(updateDraftComm.fulfilled, (state, action: PayloadAction<Communication>) => {
        state.draftUpdateStatus = CommActionState.SUCCESS;
        state.draftSelected = action.payload;
      })
      .addCase(updateDraftComm.rejected, (state) => {
        state.draftUpdateStatus = CommActionState.ERROR;
        state.draftMsg = 'Error al actualizar el borrador.';
        state.draftSelected = null;
      })
      .addCase(getDraftById.pending, (state) => {
        state.draftStatus = CommActionState.LOADING;
      })
      .addCase(getDraftById.fulfilled, (state, action: PayloadAction<DraftComm>) => {
        state.draftSelected = action.payload;
        state.draftMsg = 'Se encuentra editando un borrador.';
      })
      .addCase(getDraftById.rejected, (state, action) => {
        state.draftStatus = CommActionState.ERROR;
        state.draftMsg = action.error.message ?? 'Error al cargar el borrador.';
      })
  },
});
export const { resetDraftState } = draftCommsSlice.actions;
export default draftCommsSlice.reducer;