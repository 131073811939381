import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Alert, Button, Card, CardBody, Col, Container, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from 'react';

import { addNewCommResponse, fetchCommunicationById } from 'src/slices/Communications/thunks';
import { CommActionState, NewCommResponse } from 'src/util/types';
import { CommDetail } from "./CommDetail";
import { RootState } from 'src';
import { useAppDispatch } from 'src/hooks/useAppDispatch';

export const OpenComm = () => {
  const dispatch = useAppDispatch();
  const { selected_comm, selected_comm_msg, selected_comm_status, comm_responses } = useSelector((state: RootState) => state.communications);
  const { user } = useSelector((state: RootState) => state.login)

  document.title = "Ver comunicación";

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchCommunicationById(id));
  }, [id]);

  // Modal para respuesta
  const [modal, setmodal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);

    const contentAsHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("editorContent", contentAsHtml);
  };

  const formik = useFormik({
    initialValues: {
      editorContent: "",
      attachedFiles: null
    },
    onSubmit: values => {
      const comm_response: NewCommResponse = {
        author: {
          name: user.name,
          email: user.email,
          profile: user.profile
        },
        body: values.editorContent,
        parent_id: selected_comm!.id!,
      }
      dispatch(addNewCommResponse(comm_response))
    }
  });




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    (selected_comm_status === CommActionState.LOADING) &&
                    <Spinner
                      className="spinner-border text-primary mt-4"
                      role="status"
                    ></Spinner>
                  }
                  {
                    (selected_comm_status === CommActionState.ERROR) &&
                    <CardBody>
                      <Alert color="danger">
                        {selected_comm_msg}
                      </Alert>
                    </CardBody>
                  }
                  {
                    (selected_comm) &&
                    <CommDetail {...selected_comm} ></CommDetail>
                  }
                  <Link
                    to="#"
                    className="btn btn-light waves-effect mt-4"
                    onClick={() => { setmodal(!modal); }}>
                    <i className="mdi mdi-reply"></i> Responder
                  </Link>
                </CardBody>
              </Card>
              {/* Respuestas */}
              {
                (comm_responses?.length > 0) &&
                <React.Fragment>
                  <h4>Respuestas</h4>
                  <Card>
                    <CardBody>
                      {comm_responses.map((comm) => (
                        <CommDetail key={comm.id} {...comm} ></CommDetail>
                      ))
                      }
                    </CardBody>
                  </Card>
                </React.Fragment>
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Nueva respuesta
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}>
              <Row className='mb-3'>
                <Col>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Input
                    name='attachedFiles'
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Cerrar
            </Button>
            <Button type="button" color="primary" onClick={() => { formik.handleSubmit() }}>
              Enviar
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}
