import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, Progress, Row, Spinner } from 'reactstrap';
import { convertToRaw, EditorState } from 'draft-js';
import { FormikProps, useFormik } from 'formik';
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from 'react';

import { addDraftComm, updateDraftComm } from "src/slices/Draft Communications/thunks";
import { addNewComm, validatePassForSignComm } from 'src/slices/Communications/thunks';
import { CommActionState, COMMUNICATION_TYPE, FormValues, UploadImageResult } from '../../util/types';
import { loadDraft } from "src/util/comms";
import { onChangePassword, resetCommsState } from "src/slices/Communications/reducer";
import { resetDraftState } from "src/slices/Draft Communications/reducer";
import { RootState } from 'src';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CommunicationForm from "./CommForm";

export const NewComm = () => {
  document.title = "Nueva comunicación";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { new_comm_status, new_comm_msg, validating_status, validating_msg, savedCommId } = useSelector((state: RootState) => state.communications)
  const { draftSelected, draftStatus, draftMsg, draftUpdateStatus } = useSelector((state: RootState) => state.draftComms)

  const [progress, setProgress] = useState(0);
  const [signModal, setSignModal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initialValues, setInitialValues] = useState<FormValues>({
    commType: "",
    editorContent: "",
    mustRespond: false,
    noDeadline: false,
    deadline: "",
    attachedFiles: ""
  })

  useEffect(() => {
    if (draftSelected) {
      setInitialValues({
        commType: (draftSelected.comm_type) ?? '',
        editorContent: (draftSelected.body) ?? '',
        mustRespond: (draftSelected.must_respond) ?? false,
        deadline: (draftSelected.deadline) ?? '',
        noDeadline: false,
        attachedFiles: ""
      })
      setEditorState(loadDraft((draftSelected.body ?? '')));
    }
  }, [draftSelected])

  useEffect(() => {
    if (validating_status === CommActionState.SUCCESS) {
      redirect("/comunicaciones/general")
    }
  }, [validating_status, navigate]);

  useEffect(() => {
    if (draftStatus === CommActionState.SUCCESS) {
      redirect("/comunicaciones/borradores");
    }
  }, [draftStatus, draftUpdateStatus, navigate]);

  const redirect = (url: string) => {
    let currentProgress = 0;
      const interval = setInterval(() => {
        currentProgress += 10;
        setProgress(currentProgress);
        if (currentProgress >= 100) { clearInterval(interval); }
      }, 100);

      const timeout = setTimeout(() => {
        dispatch(resetCommsState())
        dispatch(resetDraftState())
        navigate(url);
      }, 1000);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
  }

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);

    const contentAsHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("editorContent", contentAsHtml);
  };

  const uploadImageCallback = (file: File): Promise<UploadImageResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    enableReinitialize: true,

    initialValues,
    validationSchema: Yup.object({
      commType: Yup.string(),
      editorContent: Yup.string(),
      mustRespond: Yup.boolean(),
      noDeadline: Yup.boolean(),
      deadline: Yup.string(),
    }),
    onSubmit: async values => {
      const data = {
        body: values.editorContent,
        comm_type: values.commType as COMMUNICATION_TYPE,
        deadline: values.deadline,
        id: draftSelected?.id,
        must_respond: false,
      };
      (draftSelected?.id) ? dispatch(updateDraftComm(data)) : dispatch(addDraftComm(data));
    }
  });

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleSingModal() {
    setSignModal(!signModal);
    removeBodyCss();
  }

  const saveCommForSign = async () => {
    // Si hay un borrador seleccionado, lo actualiza 
    if(draftSelected) {
      const data = {
        body: formik.values.editorContent,
        comm_type: formik.values.commType as COMMUNICATION_TYPE,
        deadline: formik.values.deadline,
        id: draftSelected?.id,
        must_respond: false,
      };
      await dispatch(updateDraftComm(data)).then(() => {
        toggleSingModal();
      });
    }
    // Si no está en una comunicación y tampoco se ha guardado el borrador, se debe guardar
    if(!savedCommId && !draftSelected){
      const data = {
        deadline: formik.values.deadline,
        body: formik.values.editorContent,
        comm_type: formik.values.commType as COMMUNICATION_TYPE,
        must_respond: false,
      }
      await dispatch(addNewComm(data)).then(() => {
        toggleSingModal();
      })
    }
    // Si ya se guardó el borrador, se levanta el modal
    if(new_comm_status === CommActionState.SUCCESS) {
      toggleSingModal();
    }
  }

  const signFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required()
    }),
    onSubmit: async ({ password }) => {
      if(draftSelected) {
        dispatch(validatePassForSignComm({ password, commId: draftSelected.id! }));
      } else {
        dispatch(validatePassForSignComm({ password, commId: savedCommId}))
      }
    }
  })

  useEffect(() => {
    dispatch(onChangePassword())
  }, [signFormik.values.password])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Comunicaciones" breadcrumbItem="Emitir nueva comunicación" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <h4 className="card-title">Ingresa la información correspondiente</h4>
                </CardHeader>
                <CardBody>
                  {draftSelected && <Alert fade={false} color="warning" className="mt-1">{draftMsg}</Alert>}

                  <CommunicationForm
                    editorState={editorState}
                    handleEditorChange={handleEditorChange}
                    uploadImageCallback={uploadImageCallback}
                    onSubmit={formik.handleSubmit}
                    formik={formik} />
                  <Row>
                    <Col className="col-12 d-flex">
                      <Button
                        color="success"
                        className="me-2"
                        type="button"
                        onClick={() => { saveCommForSign(); }}
                        data-toggle="modal"
                        disabled={formik.isSubmitting}
                      >
                        Emitir comunicación
                      </Button>
                      <Button
                        color="warning"
                        className="me-2"
                        type="button"
                        onClick={formik.submitForm}
                        disabled={formik.isSubmitting}
                      >
                        Guardar borrador
                      </Button>
                    </Col>
                  </Row>

                  {draftUpdateStatus === CommActionState.LOADING && <Spinner className="ms-2" color="primary" />}
                  {draftUpdateStatus === CommActionState.ERROR && <Alert color="danger" className="mt-4">{draftMsg}</Alert>}
                  {draftUpdateStatus === CommActionState.SUCCESS && <Alert color="success" className="mt-4">Borrador actualizado.</Alert> }
                  {draftStatus === CommActionState.SUCCESS &&
                    <React.Fragment>
                      <Alert color="success" className="mt-4">{draftMsg}</Alert>
                      <div className="success">
                        <Progress value={progress} color="success" animated striped></Progress>
                      </div>
                    </React.Fragment>
                  }
                  {new_comm_status === CommActionState.LOADING && <Spinner className="ms-2" color="primary" />}
                  {new_comm_status === CommActionState.ERROR && <Alert color="danger" className="mt-4">{new_comm_msg}</Alert>}
                  {new_comm_status === CommActionState.SUCCESS && <Alert color="success" className="mt-4">{new_comm_msg}</Alert>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={signModal}
        toggle={() => {
          toggleSingModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Firmar comunicación</h5>
          <button
            type="button"
            onClick={() => { setSignModal(false); }}
            className="close"
            data-dismiss="modal"
            aria-label="Cerrar modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={e => {
            e.preventDefault();
            signFormik.handleSubmit();
            return;
          }}>
            <Row className="my-4"> {/* password */}
              <Label htmlFor="password-input" className="col-sm-3 col-form-label">Ingrese su clave de Firma Eléctronica</Label>
              <Col sm={9}>
                <Input
                  name="password"
                  placeholder="Clave de Firma Eléctronica"
                  type="password"
                  className="form-control"
                  id="password-input"
                  onChange={signFormik.handleChange}
                  onBlur={signFormik.handleBlur}
                  value={signFormik.values.password || ""}
                  invalid={
                    signFormik.touched.password &&
                      signFormik.errors.password
                      ? true
                      : false
                  }
                />
                { signFormik.touched.password && signFormik.errors.password ? 
                  <FormFeedback type="invalid">
                    {signFormik.errors.password}
                  </FormFeedback>
                  : null
                }
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  color="success"
                  className="me-2"
                  type="submit"
                  onClick={signFormik.submitForm}
                >
                  Firmar comunicación
                </Button>
              </Col>
            </Row>
          </Form>
          {validating_status === CommActionState.LOADING && <Spinner className="ms-2" color="primary" />}
          {validating_status === CommActionState.SUCCESS &&
            <React.Fragment>
              <Alert fade={true} color="success" className="mt-4">{validating_msg}</Alert>
              <div className="success">
                <Progress value={progress} color="success" animated striped></Progress>
              </div>
            </React.Fragment>
          }
          {validating_status === CommActionState.ERROR && <Alert color="danger" className="mt-2">{validating_msg}</Alert>}
        </div>
      </Modal>
    </React.Fragment>
  )
}
