import { combineReducers } from 'redux';


import layoutReducer from "./Layout/reducer";


//login
import loginReducer from "./Auth/Login/reducer";

// File manager
import fileManagerReducer from "./FileManager/reducer";

// Communications Reducer
import communicationsReducer from "./Communications/reducer";

import draftCommsReducer from "./Draft Communications/reducer";




const rootReducer = combineReducers({
    Layout: layoutReducer,
    login: loginReducer,
    fileManager: fileManagerReducer,
    communications: communicationsReducer,
    draftComms: draftCommsReducer
})

export default rootReducer