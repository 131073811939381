import { createAsyncThunk } from '@reduxjs/toolkit';

import { addCommResponse, getComm, getComms, sendCommunication } from '../../api/api';
import { CreateComm, NewCommResponse } from 'src/util/types';
import { saveDraft } from '../../api/api';

export const fetchCommunications = createAsyncThunk(
  'communications/fetchCommunications',
  async (_, { rejectWithValue }) => {
    try {
      return await getComms();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCommunicationById = createAsyncThunk(
  'communications/fetchCommunicationById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getComm(id);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewCommResponse = createAsyncThunk(
  'communications/addNewCommResponse',
  async (newComm: NewCommResponse, { rejectWithValue }) => {
    try {
      await addCommResponse(newComm);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewComm = createAsyncThunk(
  'communications/addNewComm',
  async (newComm: CreateComm, { rejectWithValue }) => {
    try {
      const id = await saveDraft(newComm);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const validatePassForSignComm = createAsyncThunk(
  'communications/validatePassForSignComm',
  async ({password, commId}:{password: string, commId: string }, { rejectWithValue }) => {
    try {
      await sendCommunication(commId, password)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

