
export interface TempFileUpload {
  url: string;
  file: string;
}

export interface User {
  id:           string;
  email:        string;
  name:         string;
  contracts:    Contract[];
  tokenVersion: number;
  token:        string;
  profile:      string;
}

export interface Contract {
  id:   string;
  name: string;
}

export interface SubirArchivo {
  nombre: string;
  nombre_documento: string;
  descripcion: string;
  tipo: string; // Ejemplo: decreto
  fecha_emision: string | null;
  mime_type: string;
  contractid: string;
}

export interface Archivo extends SubirArchivo {
  id: string;
  fecha_incorporacion: string;
}

export interface EditarArchivo extends Omit<SubirArchivo, 'mime_type' | 'nombre_documento' | 'fecha_incorporacion'>{
  id: string;
}

export enum FileActionState {
  NOT_STARTED,
  SUCCESS,
  ERROR,
  LOADING
}

export interface RequestTempFileUploadResponse {
  fileName: string;
  url: string;
}

export interface ContractIdParams {
  contractid: string;
}

export enum COMMUNICATION_TYPE {
  SOLICITUD = 'Solicitud',
  CONSULTA = 'Consulta',
  ENTREGA_INFO = 'Entrega de información',
  INSTRUCCION = 'Instrucción',
  NOTIF_INCUMPLIMIENTO = 'Notificación de incumplimiento',
  CONSTANCIA = 'Constancia',
  ORDEN_CAMBIO = 'Orden de cambio',
  RECLAMO = 'Reclamo',
};

export interface Communication {
  author: Pick<User, "name" | "email" | "profile">;
  body: string;
  comm_type: COMMUNICATION_TYPE;
  creation_date: string;
  deadline: string | null;
  must_respond: boolean;
  organization: string;
  id: string;
  parent_id: string | null;
  read: boolean;
  totalReplies: number;
  signed?: boolean;
}

export interface OpenComm {
  open_comm: Communication;
  comm_responses: Communication[];
}

export interface NewCommResponse extends Pick<Communication, 'author' | 'body' | 'parent_id'> {}


export enum CommActionState {
  NOT_STARTED,
  SUCCESS,
  ERROR,
  LOADING
}

export interface CommunicationState {
  communications: Communication[];
  comms_status: CommActionState;
  comms_msg: string;
  selected_comm: Communication | null;
  comm_responses: Communication[];
  selected_comm_status: CommActionState;
  selected_comm_msg: string;
  new_comm_status: CommActionState;
  new_comm_msg: string;
  validating_status: CommActionState;
  validating_msg: string;
  savedCommId: string;
}

export interface CreateComm extends Pick<Communication, 'body' | 'comm_type' | 'deadline' | 'must_respond'> {}

export interface DraftComm extends Partial<Communication> {}

export interface SaveDraft extends Partial<CreateComm> {}

export interface FormValues {
  commType: string;
  editorContent: string;
  mustRespond: boolean;
  noDeadline: boolean;
  deadline: string;
  attachedFiles: string;
}

export interface UploadImageResult {
  data: {
    link: string | ArrayBuffer | null;
  };
}

export interface FormValues {
  commType: string;
  editorContent: string;
  mustRespond: boolean;
  noDeadline: boolean;
  deadline: string;
  attachedFiles: string;
}

export enum FormAction {
  EMIT,
  SAVE_DRAFT,
  DELETE_DRAFT
}