
import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { Archivo, Communication, COMMUNICATION_TYPE, ContractIdParams, EditarArchivo, SubirArchivo, TempFileUpload, User, OpenComm, NewCommResponse, CreateComm, SaveDraft, DraftComm } from "src/util/types";
import { clearUser, getUser, setUser } from "./credentialsStore";

export const ERROR_MESSAGES: { [code: number]: string } = {
	0x0001: 'Usuario o contraseña inválido, intente nuevamente',
	0x0002: 'Contraseña incorrecta',
};

const client = axios.create({ baseURL: "https://ogdthqxc81.execute-api.us-east-1.amazonaws.com/dev/" });

function processResponse(response: AxiosResponse)
{
	if (!response.data.ok)
	{
		// Handle invalid token
		if (response.data.errorCode == 0x03)
		{
			clearUser();
			return;
		}

		const message = ERROR_MESSAGES[response.data.errorCode] ??
			response.data.error;
		throw new Error(message);
	}
	return response.data.data;
}

function generateHeaders(): RawAxiosRequestHeaders
{
	const user = getUser();
	return user?.token ?
		{ token: user.token } : {};
}

async function get<T>(url: string, params?: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.get(url, { params, headers });
	return processResponse(response) as T;
}

async function post<T>(url: string, data: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.post(url, data, { headers });
	return processResponse(response) as T;
}

export const login = async (email: string, password: string) => {
	const user = await post<User>('login', { email, password });
	setUser(user);
	return user;
};
export const logout = () => {
	clearUser();
};

export const uploadFile = async (url: string, file: any) =>
{
	await client.put(
		url,
		file,
		{
			headers:
			{
				"Content-Type": file.type
			}
		});

}
export const requestTempFileUpload = async () => get<TempFileUpload>('requestTempFileUpload');
export const addFile = (tempFile: string, file: SubirArchivo) => post('addFile', { tempFile, file });
export const updateFile = async (req: EditarArchivo) => post('updateFile', req);
export const deleteFile = async (id: string) => post('deleteFile', { id });
export const getFiles = (params: ContractIdParams) => get<Archivo[]>('getFiles', params);
export const getFile = (id: string) => get<Archivo[]>('getFile', { id });
export const downloadFile = (id: string) => get<string>('downloadFile', { id });

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const mockComms: Communication[] = [
	{
		author: { name: 'Pepe Pérez', email: 'pepe@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.SOLICITUD,
		deadline: '2024-03-15T10:00:00Z',
		creation_date: '2023-03-15T10:00:00Z',
		must_respond: true,
		organization: 'Municipalidad',
		id: "1",
		parent_id: null,
		read: false,
		totalReplies: 0,
		signed: true
	},
	{
		author: { name: 'José Silva', email: 'pepe@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.SOLICITUD,
		deadline: '2024-03-15T10:00:00Z',
		creation_date: '2023-03-15T10:00:00Z',
		must_respond: true,
		organization: 'Contratista',
		id: "2",
		parent_id: null,
		read: true,
		signed: true,
		totalReplies: 0
	},
	{
		author: { name: 'Juan Gómez', email: 'juan@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.CONSULTA,
		deadline: null,
		creation_date: '2023-03-15T10:00:00Z',
		must_respond: false,
		organization: 'Municipalidad',
		id: "3",
		parent_id: null,
		read: false,
		totalReplies: 2,
		signed: true
	},
	{
		author: { name: 'Pepe Pérez', email: 'pepe@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.INSTRUCCION,
		deadline: '2024-03-15T10:00:00Z',
		creation_date: '2023-04-20T10:00:00Z',
		must_respond: true,
		organization: 'Municipalidad',
		id: "41",
		parent_id: "3",
		read: true,
		totalReplies: 0,
		signed: true
	},
	{
		author: { name: 'Juan Gómez', email: 'juan@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.CONSULTA,
		deadline: null,
		creation_date: '2023-06-05T10:00:00Z',
		must_respond: false,
		organization: 'Municipalidad',
		id: "31",
		parent_id: "3",
		read: false,
		totalReplies: 0,
		signed: true
	},
	{
		author: { name: 'Pepe Pérez', email: 'pepe@example.com', profile: 'ITS' },
		body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		comm_type: COMMUNICATION_TYPE.INSTRUCCION,
		deadline: '2024-03-15T10:00:00Z',
		creation_date: '2023-03-15T10:00:00Z',
		must_respond: true,
		organization: 'Municipalidad',
		id: "4",
		parent_id: null,
		read: false,
		totalReplies: 0,
		signed: true
	},
];


export const getAllComms = async () => {
	const comms = localStorage.getItem('comms');
	if (!comms) {
		localStorage.setItem('comms', JSON.stringify(mockComms));
		return mockComms;
	}
	return JSON.parse(comms) as Communication[];
}

export const getCommsByStatus = async (signed: boolean) => {
	const comms: Communication[] = await getAllComms();
	if(comms?.length) {
		const filteredComms = comms.filter(comm => comm.signed === signed);
		return filteredComms;
	}
	return [];
}

export const getComms = async () => {
	const comms = await getCommsByStatus(true);
	if (comms?.length) {
		const commsParents = comms.filter((comm: Communication) => comm.parent_id === null && comm.signed === true);
		commsParents.forEach((comm: Communication) => {
			comm.totalReplies = comms.filter((comm2: Communication) => comm2.parent_id === comm.id).length;
		});
		return commsParents
		
	}
	return [];
};
export const getComm = async (id: string) => {
	const comms = await getAllComms();
	const comm = comms.find(comm => comm.id === id);

	if(!comm) throw new Error(`No existe comunicacion con el id ${id}`)
		
	const comm_responses = comms.filter(comm => comm.parent_id === id);
	const open_comm: OpenComm = {
		open_comm: comm,
		comm_responses
	}
	// Mark as read
	const index = comms.findIndex((comm: Communication) => comm.id === id);
	comms[index].read = true;
	localStorage.setItem('comms', JSON.stringify(comms));
	
	return open_comm;

}; 
export const addCommResponse = async (newCommData: NewCommResponse) => {
	const comms = await getAllComms()
	// Buscar si es que existe padre
	const parentComm = comms.find(comm => comm.id === newCommData.parent_id);
	if(!parentComm) throw new Error('No existe padre.')

	// Crear objeto de nueva comunicacion
	const newComm: Communication = {
		author: newCommData.author,
		body: newCommData.body,
		comm_type: parentComm.comm_type,
		creation_date: new Date().toISOString(),
		deadline: null,
		must_respond: false,
		organization: parentComm.organization,
		id: (mockComms.length + 1).toString(),
		parent_id: newCommData.parent_id,
		read: false,
		totalReplies: 0,
		signed: true
	}
	comms.push(newComm);
	localStorage.setItem('comms', JSON.stringify(comms));
};

export const saveDraft = async (draftData: SaveDraft) => {
	const comms = await getAllComms();

	const newDraft: Communication = {
		author: { name: 'Juan Gómez', email: 'juan@example.com', profile: 'ITS' } ,
		body: (draftData.body) ?? '',
		comm_type: (draftData.comm_type as COMMUNICATION_TYPE),
		creation_date: new Date().toISOString(),
		deadline: draftData.deadline ?? null,
		id: (comms.length + 1).toString(),
		must_respond: (draftData.must_respond ?? false),
		organization: 'Municipalidad',
		parent_id: null,
		read: false,
		totalReplies: 0,
		signed: false
	}

	comms.push(newDraft);
	localStorage.setItem('comms', JSON.stringify(comms));
	await sleep(1000);
	return newDraft.id;
}

export const getDrafts = async () => {
	return await getCommsByStatus(false);
}

export const getDraft = async (id: string) => {	
	const drafts = await getCommsByStatus(false);
	if (!drafts) throw new Error('No hay borradores.');

	const draft = drafts.find(draft => draft.id === id);

	if(!draft) throw new Error(`No existe borrador con el id ${id}`)
	return draft;
}


export const updateDraft = async (draftData: DraftComm) => {
	const comms = await getAllComms();
	const oldDraft = comms.find((draft: Communication) => draft.id === draftData.id);
	if(!oldDraft) throw new Error('No existe borrador.');

	const updatedDraft: Communication = {
		author: oldDraft.author,
		body: draftData.body ?? oldDraft.body,
		comm_type: draftData.comm_type ?? oldDraft.comm_type,
		creation_date: oldDraft.creation_date,
		deadline: draftData.deadline ?? oldDraft.deadline,
		must_respond: draftData.must_respond ?? oldDraft.must_respond,
		organization: oldDraft.organization,
		id: oldDraft.id,
		parent_id: null,
		read: false,
		totalReplies: 0
	}
	const index = comms.findIndex((draft: Communication) => draft.id === oldDraft.id);
	comms[index] = updatedDraft;
	localStorage.setItem('comms', JSON.stringify(comms));
	return updatedDraft;
}

export const deleteDraft = async (id: string) => {
	const comms = await getAllComms();
	const index = comms.findIndex((draft: DraftComm) => draft.id === id);
	comms.splice(index, 1);
	localStorage.setItem('comms', JSON.stringify(comms));
}

export const sendCommunication = async (id: string, signaturePassword: string) => {
	await sleep(1000);
	if (signaturePassword !== '12345678') throw new Error('Contraseña incorrecta');
	const comms = await getAllComms();
	comms.find((comm: Communication) => comm.id === id)!.signed = true;
	localStorage.setItem('comms', JSON.stringify(comms));
	return true;
}

